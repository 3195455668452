@import "materialize/materialize.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

/* Переменные */
$baseColor: rgba(0, 0, 0, .7);

$textColor: white;
$textHover: #F2930B;

$textBold: 700;
$textThin: 500;

$textLarge: calc(2vw + 3vh);
$textNormal: calc(.4vw + 2vh);
$textSmall: calc(.15vw + 1.5vh);

$borderRadius: 18px;
$animationSpeed: .7s;
/* ------------------------ */

/* Основные элементы */
* {
    font-family: "Montserrat", serif; font-weight: $textThin;
    text-shadow: 0 4px 15px rgba(0, 0, 0, .8);
    color: $textColor;
}

#root {
    display: flex; flex-direction: column;
    min-height: 100vh;
}
body {
    overflow-y: scroll;
    margin: 0; padding: 0;
    background: url("./images/background.png") fixed 15%;
    background-size: cover;
}

.container { max-width: 100vw }
.card {
    position: relative;
    height: fit-content !important;
    background: $baseColor;
    padding: calc(1vw + 1vh) calc(2vw + 2vh);
    border-radius: $borderRadius;
    @extend .w100, .z-depth-1;

    &:before {
        width: 40%; height: calc(.3vw + .3vh);
        position: absolute; left: calc(30%); top: 0;
        transform: translateY(calc(-.15vw - .15vh));
        background: $textHover; color: rgba(0, 0, 0, 0);
        border-radius: $borderRadius; content: ".";
        @extend .z-depth-2;
    }
}

.w100 { width: 100% }
.h100 { height: 100% }

.title { font-size: $textLarge }
.normal { font-size: $textNormal }
.small { font-size: $textSmall }
small { font-size: calc(.13vw + 1.3vh) }

.bold { font-weight: $textBold }

.valign {
    display: flex;
    align-items: center;

    &.center {
        justify-content: center;
        text-align: left;
    }
}

.hoverable {
    transition: all $animationSpeed; cursor: pointer;

    &:hover, &.active { transform: scale(1.03) !important }
}
a {
    color: $textHover;
    @extend .hoverable;

    &:hover, &.active {
        box-shadow: none;
        @extend .bold;
    }
}
/* ------------------------ */

/* Общее */
.shadow {
    position: absolute; z-index: -1;
    width: 100%; height: 550px;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 28%);
    opacity: .7;
}

.menu-pointer {
    animation: pulse 1.5s infinite;
    @extend .title;
}
.menu-switcher {
    position: absolute; top: 0; z-index: 200;
    color: $textHover;
    margin-top: calc((1vw + 9vh) / 2);
    @extend .hoverable;
}
.logo {
    width: calc(1vw + 9vh); height: calc(1vw + 9vh);
    margin: 0 calc(2vw + 2vh);

    &.hoverable { box-shadow: none }
}
/* ------------------------ */

/* Голова сайта */
header {
    margin-top: calc(.5vw + .5vh);

    &> section {
        display: flex;
        @extend .valign, .container;

        &> nav {
            background: none; box-shadow: none;
            display: flex; justify-content: space-between;

            &> a { color: darken($textColor, 7) }
        }
    }
}
/* ------------------------ */

/* Основной контент */
main {
    display: flex; flex: 1 0 auto; justify-content: center;
    @extend .container;

    &> aside > .fixed-action-btn > .btn-floating {
        background: $baseColor;
        animation: pulse 1.5s infinite;
        @extend .hoverable;

        &> i { text-shadow: none }
    }
}

@media screen and (max-width: 1024px) {
    .logo {
        width: calc(2vw + 12vh); height: calc(2vw + 12vh);
    }

    header > section { justify-content: center }
    .donate > :last-child, .notification { width: 100% !important }

    header {
        margin-top: 0;

        &> section {
            flex-direction: column;

            &> nav {
                display: none;
                width: 100vw; height: 100vh;
                position: absolute; top: 0; left: 0; z-index: 100;
                background: rgba($baseColor, .9);
                flex-direction: column; justify-content: center; align-items: center;

                &> a {
                    width: 80%;
                    text-align: center;
                    font-size: $textNormal;
                }
            }
        }
    }

    .collapsible > li > .collapsible-body > div > img {
        width: 100% !important;
    }
}
/* ------------------------ */

/* Страница доната */
.donate {
    position: relative;
    display: flex; justify-content: space-between;

    &> .information {
        margin-top: 15%;
        width: 50%;

        &> :first-child {
            line-height: 120%;
            @extend .title, .bold;
        }

        &> :last-child {
            width: 70%;
            padding: calc(.8vw + .8vh);
            line-height: 1.1em;
            background: linear-gradient(90deg, rgba(0, 0, 0, .6) -6%, rgba(0, 0, 0, 0) 85%);
            box-shadow: none;
            @extend .card, .small;

            &:before {
                width: calc(.3vw + .3vh); height: 40%;
                position: absolute; left: 0; top: calc(30%);
                transform: translateX(calc(-.15vw - .15vh));
                background: $textHover; color: $textHover;
                border-radius: $borderRadius; content: ".";
                @extend .z-depth-1;
            }
        }
    }

    .payment-methods {
        display: flex;
        gap: 10px;

        label {
            cursor: pointer;

            .payment-icon-wrapper {
                position: relative;

                .payment-icon {
                    margin-top: 10%;
                    margin-left: 10%;
                    margin-right: 20px;
                    width: 90px;
                    height: auto;
                    transition: transform 0.3s ease, filter 0.3s ease;
                }

                &::after {
                    content: attr(title);
                    position: absolute;
                    bottom: -30px;
                    left: 50%;
                    transform: translateX(-50%);
                    background-color: rgba(0, 0, 0, 0.8);
                    color: #fff;
                    padding: 5px 10px;
                    border-radius: 5px;
                    font-size: 12px;
                    white-space: nowrap;
                    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
                    opacity: 0;
                    transition: opacity 0.2s ease;
                }

                &:hover::after {
                    opacity: 1;
                }

                &:hover .payment-icon {
                    transform: scale(1.1);
                }
            }
            input[type="radio"]:checked + .payment-icon-wrapper .payment-icon {
                transform: scale(1.2);
            }
        }
    }

    &> :last-child {
        display: flex; flex-direction: column;
        width: 40%;

        &> div, form { @extend .card }

        &> :first-child > :first-child {
            color: $textHover;
            @extend .normal, .bold;
        }

        &> form {
            &> .input-group {
                display: flex; flex-direction: column;
                margin-top: calc(0.1vw + 0.5vh);

                label { @extend .small }

                input {
                    height: calc(.8vw + 4vh);
                    background: lighten($baseColor, 10); color: darken($textColor, 15);
                    margin: calc(0.4vw + 0.6vh) 0 calc(0.3vw + 0.3vh) 0;
                    text-align: center; text-shadow: none;
                    border: solid 2px $textHover; border-radius: 10px;
                    transition: all $animationSpeed;
                    @extend .w100, .small;

                    &#comment, &#purse, &#promocode, &#email {
                        text-align: left;
                        padding-left: calc(1vw + 1vh);
                        width: calc(100% - (1vw + 1vh) - 2px);
                    }
                    &#comment, &#promocode {
                        border: none;
                    }

                    &::placeholder {
                        color: darken($textColor, 25);
                        @extend .small;
                    }

                    &:focus {
                        outline: none; box-shadow: none; border-bottom: solid 2px $textHover;
                        transform: scale(1.03);
                    }

                    &:disabled {
                        background: linear-gradient(180deg, $textHover 0%, darken($textHover, 7) 100%);
                        color: $textColor;
                        border: none;
                        @extend .z-depth-1;
                    }
                }
            }

            .input-flex {
                display: flex; justify-content: space-between; flex-direction: row;
                @extend .input-group;

                &> .input-group { width: 45% }

                &> :last-child { text-align: right }
            }

            small { color: #cac9c9; }

            .btn {
                height: calc(.8vw + 4vh);
                color: $textColor; background: linear-gradient(180deg, $textHover 0%, darken($textHover, 7) 100%);
                border: none; border-radius: 10px;
                padding: calc(0.3vw + 0.6vh); margin-top: calc(0.2vw + 1.5vh);
                justify-content: center; text-transform: none; text-shadow: none;

                @extend .valign, .w100, .small, .hoverable;
            }
        }
    }
}
/* ------------------------ */

/* Страница мануала */
.manual {
    margin: calc(1vw + 1vh) 0;
    align-self: center;
    @extend .w100;
}

.collapsible {
    border: none; box-shadow: none;

    &> li {
        &> .collapsible-header, &> .collapsible-body {
            background: $baseColor;
            border-radius: $borderRadius; border: none;
            transition: border-radius $animationSpeed;
        }

        &> .collapsible-header {
            display: flex; justify-content: space-between;
            padding: calc(1vw + 1vh);

            &> img { filter: invert(1) }
        }

        &> .collapsible-body {
            padding-top: 0;

            &> div {
                text-align: center;
                @extend .w100;

                &> img {
                    width: 75%;
                    border-radius: $borderRadius;
                }
            }
        }

        &.active {
            &> .collapsible-header {
                border-radius: $borderRadius $borderRadius 0 0;
            }

            &> .collapsible-body {
                border-radius: 0 0 $borderRadius $borderRadius;
            }
        }
    }
}
/* ------------------------ */

/* Страница уведомления */
:not(aside).notification {
    width: 70%;

    &> :first-child {
        color: $textHover;
        @extend .normal, .bold;
    }

    @extend .card, .small;
}

aside.notification {
    position: fixed; top: 0;
    display: flex; flex-direction: column;
    width: 100vw; height: 100vh;
    z-index: 999;
    @extend .valign, .center;

    &> div {
        display: flex; flex-direction: column;
        min-width: calc(18vw + 20vh); max-width: calc(20vw + 30vh);
        background: rgba($baseColor, .75);
        padding: calc(1vw + 1vh);
        border-radius: $borderRadius;
        font-size: calc(#{ $textNormal } * .8);
        @extend .z-depth-3, .center;

        &> h1 {
            flex-direction: column;
            margin: calc(1vw + 2vh) 0;
            font-size: $textLarge;
            @extend .valign, .center;

            &> i {
                margin-bottom: calc(1vw + 1vh);
                font-size: calc(#{ $textLarge } * 3);
            }
        }

        &> h2 {
            color: $baseColor;
            margin-bottom: calc(.5vw + .5vh);
        }

        &> button {
            display: flex; align-self: center;
            background: none;
            margin-top: calc(1vw + 1vh); margin-bottom: 0; padding: calc(.5vw + .5vh);
            border: none;
            animation: pulse calc($animationSpeed * 3) infinite;

            &> i {
                font-size: $textLarge;
            }
        }
    }
}
/* ------------------------ */

/* Подвал сайта */
footer {
    text-align: center; font-size: $textSmall;
    padding: calc(1vw + 1vh);
}
/* ------------------------ */

/* Скроллбар */
::-webkit-scrollbar-thumb {
    background: $textHover;
    border-radius: $borderRadius;
}

::-webkit-scrollbar { width: .8vw }
/* ------------------------ */

/* Анимации */
.animated { animation: fadein $animationSpeed }

.animation-in { animation: fadein $animationSpeed }
.animation-out { animation: fadeout $animationSpeed }

@keyframes fadein {
    from { opacity: 0 }
    to { opacity: 1 }
}
@keyframes fadeout {
    from { opacity: 1 }
    to { opacity: 0 }
}

@keyframes pulse {
    0% { transform: scale(1) }
    50% { transform: scale(1.1) }
    100% { transform: scale(1) }
}

.animation-scale-in {
    animation: scale-in $animationSpeed !important;
}

.animation-scale-out {
    animation: scale-out $animationSpeed !important;
}

@keyframes scale-in {
    from { transform: scale(0) }
    to { transform: scale(1) }
}

@keyframes scale-out {
    from { transform: scale(1) }
    to { transform: scale(0) }
}
/* ------------------------ */
